import React, { lazy, Suspense, useState, useEffect } from 'react';

//import Camera from 'react-feather/dist/icons/camera';
//import ArrowDown from 'react-feather/dist/icons/arrow-down';

import dataHandler from '../dataHandler';

import BarcodeInputField from '../barcodeInputField';
import CodigoInputField from '../codigoInputField';

import { ArrowDown, CameraFill } from 'react-bootstrap-icons';

import './cameraHandler.css';

const Video2 = lazy(() => import('../Video2'));

const CameraHandler2 = ({actualizarPadre}) => {

  const [ isCameraSupported, setCameraSupported ] = useState(false);
  const [ isCameraEnabled, setCameraEnabled ] = useState(dataHandler.isCameraPermissionGranted());
  const [ codigoDeBarrasEncontrado, setCodigoDeBarrasEncontrado] = useState("");
  
  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      setCameraSupported(true);
    }
  }, [])

  const onCamEnabled = () => {
    dataHandler.cameraPermissionGranted();
    setCameraEnabled(true);
  }

  const handleCallback = (childData) =>{
    setCameraEnabled(childData);
  }
  const actualizar = (devuelto) => {
    setCodigoDeBarrasEncontrado(devuelto)
    actualizarPadre(devuelto);
};

  return (
    <>
      {isCameraSupported && isCameraEnabled ?
        <Suspense fallback={<div>Cargando...</div>}>
          <Video2 parentCallback = {handleCallback} actualizar={actualizar} /> 
        </Suspense>
      :
        ""
      }
      {isCameraSupported && !isCameraEnabled ?
        <>
          <div className="cameraHandler__message">Habilita tu cámara con el botón de abajo
            <br/>
            <div className="cameraHandler__messageIcon"><ArrowDown/></div>
            <button type="button" aria-label="Enable Camera" className="btn__round camera__enable" onClick={onCamEnabled}>
              <CameraFill color="#ff8814" size={96}/>
            </button>
          </div>
        </>
        :
        ""
      }
      {!isCameraSupported ?
        <div className="cameraHandler__unsopported">
          <div>
            <p>Su dispositivo no admite el acceso a la cámara o algo salió mal<span role="img" aria-label="thinking-face">🤔</span></p>
          </div>
        </div>
        :
        ""
      }
    </>
  );
}

export default CameraHandler2;
