import React, { useState, useEffect } from "react";

const Cliente = (cliData) => {
    if(cliData.children.message!=undefined){
        return (
            <div className="container">
                <header className="jumbotron">
                    <p><strong>Cuenta no  vinculada, contáctese con soporte para habilitarla</strong></p>
                </header>
            </div>
        );
    }
    else{
        return (
            <div className="container">
                <header className="jumbotron">
                <p><strong>Número de Cliente: </strong>{cliData.children.cliente.Codigo}</p>   
                <p><strong>Nombre Fantasia: </strong>{cliData.children.cliente.NombreFantasia}</p>
                <p><strong>Razón Social: </strong>{cliData.children.cliente.RazonSocial}</p>
                <p><strong>CUIT: </strong>{cliData.children.cliente.Cuit}</p>
                <p><strong>Telefono: </strong>{cliData.children.cliente.Telefono}</p>
                <p><strong>Dirección: </strong>{cliData.children.cliente.Direccion}</p>
                <p><strong>Condición Frente AFIP: </strong>
                {
                    cliData.children.cliente.CondicionFrenteAFIP=="MO" ? "Monotributista" 
                    :
                    cliData.children.cliente.CondicionFrenteAFIP=="RI" ? "Responsable Inscripto" :
                    cliData.children.cliente.CondicionFrenteAFIP=="CF" ? "Consumidor Final":
                    cliData.children.cliente.CondicionFrenteAFIP=="EX" ? "Excento":
                    ""
                }
                </p>
                </header>
            </div>
        );
    }
}
    
export default Cliente;