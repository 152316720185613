import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

import ControlFotos from "./ControlFotos"; 

import {Container, Row, Col} from 'react-bootstrap/';


const BoardAdmin = () => {
  const [content, setContent] = useState("");
  const [responseCode, setResponseCode] = useState("");
  
  useEffect(() => {
    UserService.getAdminBoard().then(
      (response) => {
        setContent(response.data);
        setResponseCode(response.status)
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);


  if(responseCode===200){
    return (
      <Container>
      <h3>Administrador</h3>
        {
          content !== undefined && content !== 'No token provided!'? 
         <ControlFotos></ControlFotos>
          :
          null
        } 
      </Container>
    );
  }
  else{
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{content}</h3>
        </header>
      </div>
    );
  }
};

export default BoardAdmin;
