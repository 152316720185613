import axios from "axios";
import authHeader from "./auth-header";

require('dotenv').config();

const API_URL = process.env.REACT_APP_URL+"/api/test/"; //"http://localhost:8080/api/test/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

const resetPasswordLogeado = (password) => {
  let user = JSON.parse(localStorage.getItem('user'));
  return axios.post(process.env.REACT_APP_URL + "/api/user/resetPasswordLogeado/"+ user.id ,{password},{ headers: authHeader() });
};

const UserService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  resetPasswordLogeado
};

export default UserService;
