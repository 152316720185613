import axios from "axios";
import authHeader from "./auth-header";
require('dotenv').config();

const API_URL =  process.env.REACT_APP_URL + "/api/art/"; //"http://localhost:8080/api/art/";

const getTodos = async () => {
    const resultado = await axios.get(API_URL + "all", { headers: authHeader() ,  'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',} );
    return resultado;
};

const getFotosArticulo = async (id) => {
  const resultado = await axios.get(API_URL + "fotos/" + id, { headers: authHeader() });
  return resultado;
};

const getOfertas = async () => {
  const resultado = await axios.get(API_URL + "ofertas", { headers: authHeader(),  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0', });
  return resultado;
};

const getTodosSinObservacion = async () => {
  const resultado = await axios.get(API_URL + "allsinobs", { headers: authHeader() });
  return resultado;
};

const getTodosAdmin = async () => {
  const resultado = await axios.get(API_URL + "todosAdmin", { headers: authHeader() });
  return resultado;
};

const deleteFoto = async (id,nombre) => {
  const resultado = await axios.delete(API_URL + "fotos/" + id + "/" + nombre, { headers: authHeader() });
  return resultado;
};

const uploadFotos = async (image,id) => {
  return await axios({
    method: "post",
    url: API_URL + "fotos/" + id ,
    data: image,
    headers: { "Content-Type": "multipart/form-data" } && authHeader(),
  });
};

/*
const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};
*/
const ArticuloService = {
    getTodos,
    getFotosArticulo,
    getOfertas,
    getTodosSinObservacion,
    getTodosAdmin,
    deleteFoto,
    uploadFotos
};

export default ArticuloService;