import React, { useState, useEffect , useRef} from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import Articulos from "./Articulo"; 

import {Container, Row, Col} from 'react-bootstrap/';

import ArticuloService from "../services/articulo.service";

import {Button} from 'react-bootstrap/';

import { Trash3Fill, FileEarmarkPlusFill, CloudUploadFill } from 'react-bootstrap-icons';

import {toast } from 'react-toastify';

import {Modal} from 'react-bootstrap/';

import Lottie from "react-lottie";

// SWIPER //
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import "./swiper/styles_swiper.css";

// import required modules
import { Navigation } from "swiper";
// FIN SWIPER //


import * as upload from "../assets/upload.json";

import * as deleteFile from "../assets/delete-file.json";

const API_URL = process.env.REACT_APP_URL;

const ModalControlFotosMobile = ({articulo}) => {
  const [content, setContent] = useState("");

    // Inicio Fotos //
    const [BASEURL, setBASEURL]  = useState();
    const [image, setImage]  = useState();
    // Fin Fotos //

    // Inicio Galeria //

    const [index, setIndex]  = useState();
    // Fin Galeria //

   // Inicio Upload Files // 
    const [selectedFiles, setSelectedFile] = useState([null]);
    const aRef = useRef(null);
   // Fin Upload Files //

      // Modal Resultado //
      const [tituloModalCantidadCero, setTituloModalCantidadCero] = useState("");
      const [fullscreenCantidadCero, setFullscreenCantidadCero] = useState(true);
      const [textoCantidadCero, setTextoCantidadCero] = useState("");
      const [showCantidadCero, setShowCantidadCero] = useState(false);
      // Fin Modal Resultado //

    // Inicio Animaciones //
    const [uploadingData, setUploadingData] = useState(false);
    const [deleteData, setDeleteData] = useState(false);
    // Fin Animaciones //

  useEffect(() => {
    setBASEURL(API_URL + "/Images/"+articulo[0]+"/");
    ArticuloService.getFotosArticulo(articulo[0]).then(
      (response) => {
        console.log("ID:" + articulo[0]);
        console.log(response.data.files)
        if(response.data.files[0]=="Gris.png"){
          setImage("/public/Gris.png")
        }
        else{
          setImage(response.data.files);
        }
        //response.data.map((articulo) => console.log(articulo));
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setContent(_content);
      }
    );
  }, []);

  
  const handleSubmit = async(event) => {
    if(selectedFiles[0]!==null){
      event.preventDefault();
      setUploadingData(true);
      const formData = new FormData();
      Array.from(selectedFiles).forEach(file => { formData.append("image", file); });
      //formData.append("files", selectedFiles);
      ArticuloService.uploadFotos(formData,articulo[0]).then(
      (response) => {
        setUploadingData(false);
        setSelectedFile([null]);
        aRef.current.value = null;
        toast.success('Imagen/es Cargadas Correctamente', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          ArticuloService.getFotosArticulo(articulo[0]).then(
            (response) => {
              console.log("ID:" + articulo[0]);
              console.log(response.data.files)
              if(response.data.files[0]=="Gris.png"){
                setImage("/public/Gris.png")
              }
              else{
                setImage(response.data.files);
              }
              //response.data.map((articulo) => console.log(articulo));
            },
            (error) => {
              const _content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              setContent(_content);
              toast.error(_content, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
              setUploadingData(false);
            }
          );  
      },
      (error) => {
        setUploadingData(false);
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setContent(_content);
        toast.error(_content, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    );
    }
    else{
      event.preventDefault();
      toast.error("Seleccione imagenes para cargar", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    
  }

  const handleFileSelect = (event) => {
    let error = false;
    Array.from(event.target.files).forEach(file => {
      if (file) {
        let item = file;
        var pattern = /image-*/;
        if (!item.type.match(pattern)) {
          toast.error("Ingrese solamente fotografías en el formato válido", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            error = true  
          aRef.current.value = null;
          return;
        }  
    }});
    if(!error){
      setSelectedFile(event.target.files)
    }
  }

  const cerrarModalCantidadCero = () => {
    setShowCantidadCero(false);
  }
  const cerrarModalEliminaFoto = () => {
    if(image!=="/public/Gris.png"){
      setDeleteData(true);
      console.log("swiper");
      const swiper = document.querySelector('.swiper').swiper;
      console.log(swiper.realIndex);
      ArticuloService.deleteFoto(articulo[0],image[swiper.realIndex]).then(
        (response) => {
          console.log("Foto Eliminada correctamente");
          setShowCantidadCero(false);
          toast.success('Imagen Eliminada Correctamente', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            ArticuloService.getFotosArticulo(articulo[0]).then(
              (response) => {
                console.log("ID:" + articulo[0]);
                console.log(response.data.files)
                if(response.data.files[0]=="Gris.png"){
                  setImage("/public/Gris.png")
                }
                else{
                  setImage(response.data.files);
                }
                //response.data.map((articulo) => console.log(articulo));
                setDeleteData(false);
              },
              (error) => {
                const _content =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
                setContent(_content);
                setDeleteData(false);
                toast.error("Ocurrió un error, intente nuevamente", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
              }
            );  
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          setContent(_content);
          setDeleteData(false);
          toast.error("Ocurrió un error, intente nuevamente", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
      );
  }
  else{
      toast.error('No exiten imagenes para este artículo', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
  }
  }

  const uploadOptions = {
    loop: true,
    autoplay: true,
    animationData: upload.default,
    rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
    },
  };
  const deleteFileOptions = {
    loop: true,
    autoplay: true,
    animationData: deleteFile.default,
    rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
    },
  };


  return (
    <Container>
      { deleteData ? (
          <Lottie options={deleteFileOptions} height={200} width={200} />
        ) : null
      }
      { uploadingData ? (
            <Lottie options={uploadOptions} height={200} width={200} />
          ) : (
            <div>
              <Row className="d-flex justify-content-center">
            <Col sm={8}>
            <Swiper navigation={true} modules={[Navigation]} onSlideChange={() => {}} className="mySwiper">
            {
                    image !== undefined && image !== "/public/Gris.png" ? 
                    image.map((i, el) => {
                        return <SwiperSlide><img  src={BASEURL+i}/></SwiperSlide>;
                    })
                    : 
                    console.log("No tiene foto")
                    }
            </Swiper>
            </Col>
              </Row>
              <Row className="d-flex justify-content-center">  
                  <Button variant="light" onClick={() => {
                    setShowCantidadCero(true);
                    } }><Trash3Fill size={30}/></Button>  
            <Col sm={8}>
              <form onSubmit={handleSubmit}>
                <input type="file" accept="image/*" multiple onChange={handleFileSelect} ref={aRef} />
                <input type="submit" value="Subir Fotos" />
              </form>    
            </Col>
            </Row>
            <Modal show={showCantidadCero} fullscreen={true} onHide={() => setShowCantidadCero(false)} >
              <Modal.Header>
                <Modal.Title>Borrar imagen</Modal.Title>
                <Button variant="outline-danger" onClick={() => cerrarModalCantidadCero()}>X</Button>
              </Modal.Header>
              <Modal.Body>
                  Desea Eliminar la imagen?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-danger" onClick={() => cerrarModalEliminaFoto()}>Eliminar Articulo</Button>
                <Button variant="outline-success" onClick={() => cerrarModalCantidadCero()}>Salir</Button>
              </Modal.Footer>
            </Modal>
            </div>
          )}
  </Container>
);
};

export default ModalControlFotosMobile;