import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import {Row, Col, Card} from 'react-bootstrap/';

import AuthService from "../services/auth.service";
import wsp from "../assets/wsp.png";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Campo requerido!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Este no es un email valido.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        El usuario debe tener entre 3 y 20 caracteres.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        La contraseña debe tener entre 6 y 40 caracteres.
      </div>
    );
  }
};

const vganancia = (value) => {
  if (Number.isNaN(Number.parseFloat(value))) {
    return (
      <div className="alert alert-danger" role="alert">
        La ganancia debe ser un número.
      </div>
    );
  }
};

const Register = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [ganancia, setGanancia] = useState("");
  const [CUITCUIL, setCUITCUIL] = useState("");
  const [usernamePasar, setUsernamePasar] = useState("");
  const [CUITCUILPasar, setCUITCUILPasar] = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeGanancia = (e) => {
    const ganancia = e.target.value;
    setGanancia(ganancia);
  };

  const onChangeCUITCUIL = (e) => {
    const auxCUITCUIL = e.target.value;
    setCUITCUIL(auxCUITCUIL);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.register(username, email, password, ganancia, CUITCUIL).then(
        (response) => {
          if(response.data.message==="User was registered successfully!"){
            setUsernamePasar(username);
            setCUITCUILPasar(CUITCUIL);
            setMessage("Usuario creado correctamente, contáctese con su vendedor para que habiliten su cuenta.");
            
          }
          setSuccessful(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
              setMessage(resMessage);
              setSuccessful(false);
              if(error.response.data.message==="Failed! Username is already in use!"){
                setMessage("El usuario ya se encuentra utilizado");
                setSuccessful(false);
              }
              if(error.response.data.message==="Failed! Email is already in use!"){
                setMessage("El email ya se encuentra utilizado");
                setSuccessful(false);
              }
             
        }
      );
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container" style = {{borderColor: "orange", backgroundColor : "white"}}>
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="username">Usuario</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Contraseña</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="ganancia">Ganancia</label>
                <Input
                  type="number"
                  className="form-control"
                  name="ganancia"
                  value={ganancia}
                  onChange={onChangeGanancia}
                  validations={[required, vganancia]}
                />
              </div>
              <div className="form-group">
                <label htmlFor="CUITCUIL">CUIT-CUIL Del Titular</label>
                <Input
                  type="string"
                  className="form-control"
                  name="CUITCUIL"
                  value={CUITCUIL}
                  onChange={onChangeCUITCUIL}
                />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block">Registrarse</button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                  
                }
                role="alert"
              >
                {message}
                <Row className="d-flex justify-content-center">
                  <Col  xs={14}>{
                      successful === false  ? 
                      <a href="https://wa.me/5492644605714?text=Hola, quisiera hacer una consulta." target="_blank"> 
                      <Card className="bg-dark text-white">
                      <Card.Img variant="top" src={wsp}/>
                      <Card.Body>
                        <Card.Title>
                        <p>Contáctese con nosotros por WhatsApp José Karam E Hijos</p>
                        </Card.Title>
                      </Card.Body>
                    </Card></a>
                    :
                      <a href={"https://wa.me/5492644605714?text=Hola, quisiera habilitar mi usuario. Este es: " + usernamePasar.toString()  + " vinculado a " + CUITCUILPasar.toString() + " desde ya muchas gracias."}  target="_blank">
                        <Card className="bg-dark text-white">
                      <Card.Img variant="top" src={wsp}/>
                      <Card.Body>
                        <Card.Title>
                        <p>Contáctese con nosotros por WhatsApp José Karam E Hijos</p>
                        </Card.Title>
                      </Card.Body>
                    </Card> </a>
                  }
                    
                  </Col>
                </Row>
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Register;
