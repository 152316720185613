import React, { useState, useEffect, useRef } from "react";
import {Container, Row, Col, Button, Modal, Form} from 'react-bootstrap/';
import { useMediaQuery } from 'react-responsive';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MUIDataTable from "mui-datatables";
import { MDBInputGroup } from 'mdb-react-ui-kit';
import { Search, FileEarmarkArrowDownFill, Trash3Fill } from 'react-bootstrap-icons';
import { Page, Text, View, Document, StyleSheet, PDFViewer,PDFDownloadLink, Fragment  } from '@react-pdf/renderer';
import Invoice from './reports/Invoice'
import EventBus from "../common/EventBus";

import PedidoService from "../services/pedido.service";

import {toast } from 'react-toastify';


import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const dayjs = require('dayjs');

//TODO Hacer para refacturar
//TODO En los incompletos hay que ver que mostramos. Idea: Poner una lupita más en los incompletos para mostrar los articulos que están faltantes.

const Pedidos = () => {
  const [content, setContent] = useState("");
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const containerRef = useRef(null);
  const [items, setItems] = useState([]);
  const [detalle, setDetalle] = useState([]);
  const [filaElegida, setFilaElegida] = useState([]);
  const [nombreElegido, setNombreElegido] = useState("");
  // Modal //
  const [tituloModal, setTituloModal] = useState("");
  const [rowGuardada, setRowGuardada] = useState("");
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [elegido, setElegido] = useState(false);
  const [texto, setTexto] = useState("");
  const cerrarModal = () => {
    setShow(false);
  }
  // Fin Modal //
  // Modal Card//
  const [tituloModalCard, setTituloModalCard] = useState("Art");
  const [fullscreenCard, setFullscreenCard] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [dataArt, setDataArt] = useState();
  // Fin Modal Card//
  // Modal Celu//
  const [tituloModalCelu, setTituloModalCelu] = useState("Art");
  const [fullscreenCelu, setFullscreenCelu] = useState(true);
  const [showCelu, setShowCelu] = useState(false);
  const [dataPedido, setDataPedido] = useState("");
  // Fin Modal Celu//

  const [startDate, setStartDate] = useState(new Date());
  const[aux, setAux] = useState("");
  const[auxCel, setAuxCel] = useState("");

  useEffect(() => {
    PedidoService.getPedidos().then(
        (response) => {
          //console.log(response.data)
          //response.data.map((articulo) => console.log(articulo));
          setItems(response.data);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
            }
          setContent(_content);
        }
      );
  }, []);
  const columns = 
  [
    {name:"Fecha_Pedido",
  label:"Fecha",
  options: {
    customBodyRender: (value, tableMeta, updateValue) => {
        return dayjs(value).format('DD/MM/YYYY H:m:ss');
      }
    
  },
  setCellProps: () => ({style: {whiteSpace:'nowrap'}})
  }, 
  {
    name:"idPedido",
    label:"Número",
    options: {
        //display: 'excluded'
        customBodyRender: (value, tableMeta, updateValue) => {
            //let aux = "0009-"+ value.toString().padStart(8,'0');
            let aux = "N°"+ value.toString().padStart(8,'0');
            return  aux;
          }
    }
  }
  ,
  {
    name:"TotalPedido",
    label:"Total",
    options : {
        customBodyRender: (value, tableMeta, updateValue) => {
          return "$" + (value);
        }
      },
  }, 
  {
    name:"Observaciones",
    label:"Observaciones",
    options: {
      display: 'excluded'
  }
  }, 
  {
    name:"",
    label:"",
    options : {
        customBodyRender: (value, tableMeta, updateValue) => {
          if(!isTabletOrMobile){
            return (
              <MDBInputGroup className='mb-3'>
                <Button variant="light" onClick={() => {
                      PedidoService.getMovimientoPedido(tableMeta.rowData[1]).then(
                        (response) => {
                          //console.log(response.data)
                          //response.data.map((articulo) => console.log(articulo));
                          setFilaElegida(tableMeta);
                          setDetalle(response.data);
                          //setNombreElegido("Pedido " + "0009-"+ tableMeta.rowData[1].toString().padStart(8,'0'));
                          setNombreElegido("Pedido N°" + tableMeta.rowData[1].toString().padStart(8,'0'));
                          setShow(true);
                        },
                        (error) => {
                          //TODO manejar el error 
                          const _content =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                    
                          setContent(_content);
                        }
                      );
                   }} style={{ backgroundColor: "transparent",
                      backgroundRepeat: "no-repeat",
                      border: "none",
                      cursor: "pointer",
                      overflow: "hidden",
                      outline: "none"}}><Search size={30}/></Button>
                      {
                        tableMeta.rowData[6] === 0 ?  <Button variant="light" onClick={() => {
                          console.log("Borra");
                          setShowCard(true);
                          setAux(tableMeta.rowData[1]);
                        }} style={{ backgroundColor: "transparent",
                            backgroundRepeat: "no-repeat",
                            border: "none",
                            cursor: "pointer",
                            overflow: "hidden",
                            outline: "none"}}><Trash3Fill size={30}/></Button>
                            : console.log("")
                      }
              </MDBInputGroup>
            );
      }
          }   
      },
  },
  {
    name:"cliente",
    label:"",
    options: {
      display: 'excluded'
  }
  } ,
  {
    name:"Completo",
    label:"Estado",
    options : {
      customBodyRender: (value, tableMeta, updateValue) => {
        if(value === 0){
          return "En espera"
        }
        if(value === -1){
          return "Incompleto"
        }  
        if(value === 1){
          return "Finalizado"
        }
        if(value === -2){
          return "Anulado"
        }
      }
    },
  },
];

  const options = {
    filterType: 'checkbox',
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions :100,
    selectToolbarPlacement: "replace",
    responsive: "simple",
    searchOpen : true,
    searchAlwaysOpen : true,
    searchPlaceholder: 'Buscar Pedido',
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download:false,
    print:false,
    fixedHeader: true,
    fixedSelectColumn: true,
    onSearchClose: ()=>{
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
      if(expandedRows.data.length>1){
        let original = expandedRows.data;
        let aux = expandedRows.data.slice(dataIndex,-1);
        expandedRows.data = aux;
        setMostradas(aux);
        return true;
      }
      else{
        
      }
      //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
      return true;
      */
      //if (dataIndex === 3 || dataIndex === 4) return false;
  
          // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
          if (expandedRows.data.length > 8 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0)
            return false;
          return true;
    },
    isRowSelectable:() => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData,rowMeta) => {
      if(isTabletOrMobile){
        setDataPedido(rowData);
        setTituloModalCelu(rowData[1]);
        let aux = rowData[1].replace('N°', '');
        let aux2 = parseInt(aux);
        setAuxCel(aux2);
        setShowCelu(true);
      }
    },
    renderExpandableRow: (rowData, rowMeta) => {
      if(!isTabletOrMobile){
        const colSpan = rowData.length + 1;
        return (
          <TableRow>
            <TableCell colSpan={colSpan} >
              <Row>
                <Col>
                wasa
                </Col>
                <Col>
                wasa2
                </Col>
              </Row>
            </TableCell>
          </TableRow>
        );
      }
    },
    
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>{
      console.log(curExpanded, allExpanded, rowsExpanded)
    }
  };

 
/*
  return (
    <Container >
      <Row className="d-flex justify-content-center">
        <Col>
        <h6>Buscar por estado</h6>
        <div key={`inline-radio`} className="mb-3">
          <Form.Check
            inline
            label="Todos"
            name="group1"
            type="radio"
            id="1"
          />
          <Form.Check
            inline
            label="Finalizados"
            name="group1"
            type="radio"
            id="2"
          />
          <Form.Check
            inline
            label="Incompletos"
            name="group1"
            type="radio"
            id="3"
          />
          <Form.Check
            inline
            label="Anulados"
            name="group1"
            type="radio"
            id="4"
          />
        </div>
        </Col>
        <Col style={{zIndex : "1"}}> 
          <h6>Buscar por fecha</h6>
          <DatePicker selected={startDate} onChange={(date:Date) => setStartDate(date)} />
  
        </Col>
      </Row>
     
       <Row className="d-flex justify-content-center">
        <Col >
          <MUIDataTable 
            ref={containerRef}
            title={"Lista de Artículos"}
            data={items}
            columns={columns}
            options={options}
            id="tabla">
          </MUIDataTable>
        </Col>
     </Row>
     <Modal size="lg" show={show} fullscreen={fullscreen} onHide={() => setShow(false)} >
        <Modal.Header>
          <Modal.Title>{tituloModal}</Modal.Title>
          <Button variant="outline-danger" onClick={() => cerrarModal()}>X</Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <PDFViewer width="1000" height="600" className="app" >
                <Invoice invoice={filaElegida} filas={detalle}/>
            </PDFViewer>
          </Row>
          <Row className="d-flex justify-content-center">
            <a>
                <PDFDownloadLink document={<Invoice invoice={filaElegida} filas={detalle}/>} fileName={nombreElegido+".pdf"}>
                  <FileEarmarkArrowDownFill size={30}/>
                  {({ blob, url, loading, error }) => (
                  loading ? 'Loading document...' : 'Download now!'
                )}
              </PDFDownloadLink>
            </a>
          </Row>
        </Modal.Body>
       <Modal.Footer>
          <Button variant="outline-danger" onClick={() => cerrarModal()}>Salir</Button>
       </Modal.Footer>
      </Modal>
     </Container>
  );
};
*/

const cerrarModalCard = () => {
  setShowCard(false);
}
const cerrarModalCelu = () => {
  setShowCelu(false);
}
const modalCeluOnShow = () => {
  console.log("entra on Show ");
  PedidoService.getMovimientoPedido(auxCel).then(
    (response) => {
      //console.log(response.data)
      //response.data.map((articulo) => console.log(articulo));
      let tableMeta = {rowData: dataPedido};

      setFilaElegida(tableMeta);
      setDetalle(response.data);
      //setNombreElegido("Pedido " + "0009-"+ tableMeta.rowData[1].toString().padStart(8,'0'));
      setNombreElegido("Pedido N°" + auxCel.toString().padStart(8,'0'));
      //setShow(true);
    },
    (error) => {
      //TODO manejar el error 
      const _content =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      setContent(_content);
    }
  );
}
const cerrarModalCardBorrandoItem = () => {
////
PedidoService.anularPedidos(aux).then(
  (response) => {
    console.log(response.data);
    
    toast.success('Pedido Anulado correctamente', {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
      if(isTabletOrMobile){
        cerrarModalCelu();
      }
      // Recargo la tabla
      PedidoService.getPedidos().then(
        (response) => {
          //console.log(response.data)
          //response.data.map((articulo) => console.log(articulo));
          setItems(response.data);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
            }
          setContent(_content);
        }
      );
  },
  (error) => {
    const _content =
      (error.response && error.response.data) ||
      error.message ||
      error.toString();
      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    setContent(_content);
  }
);
////
setShowCard(false);
}

return (
  <Container >
     <Row className="d-flex justify-content-center">
      <Col >
        <MUIDataTable 
          ref={containerRef}
          title={"Lista de Artículos"}
          data={items}
          columns={columns}
          options={options}
          id="tabla">
        </MUIDataTable>
      </Col>
   </Row>
   <Modal size="lg" show={show} fullscreen={fullscreen} onHide={() => setShow(false)} >
      <Modal.Header>
        <Modal.Title>{tituloModal}</Modal.Title>
        <Button variant="outline-danger" onClick={() => cerrarModal()}>X</Button>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex justify-content-center">
          <PDFViewer width="1000" height="600" className="app" >
              <Invoice invoice={filaElegida} filas={detalle}/>
          </PDFViewer>
        </Row>
        <Row className="d-flex justify-content-center">
          <a>
              <PDFDownloadLink document={<Invoice invoice={filaElegida} filas={detalle}/>} fileName={nombreElegido+".pdf"}>
                <FileEarmarkArrowDownFill size={30}/>
                {({ blob, url, loading, error }) => (
                loading ? 'Loading document...' : 'Download now!'
              )}
            </PDFDownloadLink>
          </a>
        </Row>
      </Modal.Body>
     <Modal.Footer>
        <Button variant="outline-danger" onClick={() => cerrarModal()}>Salir</Button>
     </Modal.Footer>
    </Modal>
    <Modal show={showCard} fullscreen={fullscreenCard} onHide={() => cerrarModalCard()} >
        <Modal.Header>
          <Modal.Title>Alerta</Modal.Title>
          <Button variant="outline-danger" onClick={() => cerrarModalCard()}>X</Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <Col>
              Desea Anular el Pedido?
            </Col>
          </Row>
        </Modal.Body>
       <Modal.Footer>
          <Button variant="outline-danger" onClick={() => cerrarModalCardBorrandoItem()}>Anular</Button>
          <Button variant="success" onClick={() => cerrarModalCard()}>Salir</Button>
       </Modal.Footer>
      </Modal>
      <Modal show={showCelu} fullscreen={fullscreenCelu} onHide={() => cerrarModalCelu()}  onEnter= {() => modalCeluOnShow()} >
        <Modal.Header>
          <Modal.Title>Pedido N°: {tituloModalCelu.replace("N°", "")} </Modal.Title>
          <Button variant="outline-danger" onClick={() => cerrarModalCelu()}>X</Button>
        </Modal.Header>
        <Modal.Body>
          <Row style={{paddingLeft:"10px"}}>
          <Col>
            <Row className="d-flex justify-content-center">
              {
                (dataPedido !== "") ? 
                <Row >
                  <Col>
                  <Row><b>Fecha: </b>{dataPedido[0]}</Row>
                  <Row><b>Total: </b>{dataPedido[2]}</Row>
                  <Row><b>Estado: </b>{dataPedido[6]}</Row>
                  </Col>
                </Row>
                :
                console.log("")
              }
              
            </Row>
          </Col>
         <Col>
         {
              (dataPedido !== "" && dataPedido[6] ==="En espera") ?
              <Row className="d-flex justify-content-center">
                <Button variant="light" onClick={() => {
                console.log("Borra");
                setShowCard(true);
                setAux(auxCel);
              }} style={{ backgroundColor: "transparent",
                  backgroundRepeat: "no-repeat",
                  border: "none",
                  cursor: "pointer",
                  overflow: "hidden",
                  outline: "none"}}><Trash3Fill size={30}/></Button>
              </Row>
            :
            console.log("")
          }
          {
              (dataPedido !== "" && filaElegida.rowData !== undefined) ?
              <Row className="d-flex justify-content-center">
                <PDFDownloadLink document={<Invoice invoice={filaElegida} filas={detalle}/>} fileName={nombreElegido+".pdf"}>
                        <FileEarmarkArrowDownFill size={30}/>
                        {({ blob, url, loading, error }) => (
                        loading ? 'Loading document...' : 'Download now!'
                      )}
                    </PDFDownloadLink>
              </Row>
            :
            console.log("")
          }
         </Col>
         </Row>
        </Modal.Body>
       <Modal.Footer>
          <Button variant="success" onClick={() => cerrarModalCelu()}>Salir</Button>
       </Modal.Footer>
      </Modal>
   </Container>
);
                };
export default Pedidos;

/*
<PDFViewer width="800" height="800" >
              <MyDocument />
            </PDFViewer>
            */