import axios from "axios";
require('dotenv').config();

const API_URL =  process.env.REACT_APP_URL+ "/api/auth/" ; //http://localhost:8080/api/auth/";

const register = (username, email, password, ganancia,CUITCUIL) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
    ganancia,
    CUITCUIL
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const forgotpassword = (email) => {
  return axios.post(API_URL + "forgotpassword", {
    email
  });
};

const resetpassword = (token,password) => {
  return axios.post(API_URL + "resetpassword/"+ token ,{
    password
  });
};
const validateresetpassword = (token) => {
  return axios.get(API_URL + "validateresetpassword/" + token);
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  forgotpassword,
  resetpassword,
  validateresetpassword
};

export default AuthService;
